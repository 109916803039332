
import { Component, Vue } from 'vue-property-decorator'

import NodeCard from '@/components/NodeCard.vue'
import { NodeInfo, NodeInfoFunctions } from '@/types/state'


import { UserRole } from '@/enums'


@Component({
  components: {
    NodeCard
  }
})
export default class DefaultHome extends Vue {
searchValue = ''
aliasFilter = 1
filterPrepared = 0

resetFilters(): void {
  this.searchValue = ""
  this.filterPrepared = 0
}

containsTerm(target: string, term: string): boolean {
  return target.toLowerCase().includes(term.toLowerCase())
}

filterNodesByAlias(nodes: Array<NodeInfo>): Array<NodeInfo> {
  return (this.aliasFilter == 0) ? 
    nodes :
    (this.aliasFilter == 1) ? 
      NodeInfoFunctions.sortDesc(nodes) :
      NodeInfoFunctions.sortAsc(nodes)
}

addToComparison(node: NodeInfo): void {
  this.$store.commit('default/ADD_TO_COMPARISON', { ...node })
}

setDetailNode(node: NodeInfo): void {
  this.$store.commit('detailNode/SET_NODE_INFO', { ...node })
  this.$store.commit('meters/SET_NODE_INFO', { ...node })
  this.$store.commit('modNode/SET_NODE_INFO', { ...node })
  this.$store.commit('emraPageInfo/SET_NODE_INFO', { ...node } )
  this.$router.push({ name: this.userRole == UserRole.User ? "DataPage" : (this.userRole != UserRole.None ? "ModEMRAPage" : "UserHome") })
}

get aliasFilterIcon(): string {
  switch(this.aliasFilter) {
    case 0:   return 'mdi-sort-alphabetical-variant'
    case 1:   return 'mdi-sort-alphabetical-ascending'
    default:  return 'mdi-sort-alphabetical-descending'
  }
}

get readyForPlacementNodes(): Array<NodeInfo> {
  return this.isModOrAdmin
    ? this.$store.getters['default/NodeInfos'].filter((n: NodeInfo) => n.readyForPlacement)
    : []
}

get preparedNodes(): Array<NodeInfo> {
  return this.isModOrAdmin
    ? this.$store.getters['default/NodeInfos'].filter((n: NodeInfo) => n.prepared)
    : []
}

get toBePreparedNodes(): Array<NodeInfo> {
  return this.isModOrAdmin
    ? this.$store.getters['default/NodeInfos'].filter((n: NodeInfo) => n.toBePrepared)
    : []
}

get filteredReadyForPlacementNodes(): Array<NodeInfo> {
  const filtered = this.readyForPlacementNodes.filter(n => this.containsTerm(n.alias, this.searchValue)
    && (this.filterPrepared === 0 || this.filterPrepared === 1)
  )
  return this.filterNodesByAlias(filtered)
}

get filteredPreparedNodes(): Array<NodeInfo> {
  const filtered = this.preparedNodes.filter(n => this.containsTerm(n.alias, this.searchValue)
    && (this.filterPrepared === 0 || this.filterPrepared === 2)
  )
  return this.filterNodesByAlias(filtered)
}

get filteredToBePreparedNodes(): Array<NodeInfo> {
  const filtered = this.toBePreparedNodes.filter(n => this.containsTerm(n.alias, this.searchValue)
    && (this.filterPrepared === 0 || this.filterPrepared === 3)
  )
  return this.filterNodesByAlias(filtered)
}


get updatedNodes(): Array<any> {
    return this.$store.getters["default/UpdatedMeters"]?.map((um: any) => um.nodeId)
}

get isLoaded(): boolean {
  return this.$store.getters['default/IsPreloaded']
}

get userRole(): string {
  return this.$store.getters['identity/role']
}

get isModOrAdmin(): boolean {
  return this.userRole == 'Moderator' || this.userRole == 'Admin'
}

created(): void {
  this.$store.commit('detailNode/SET_PRELOADED', false)
}
}
